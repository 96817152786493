import React from 'react'
import './AboutStyles.css'
import { Link } from 'react-router-dom';

const scrollToTop = () => {
    window.scrollTo(0, 0)
}

const About = () => {
    return (
        <div className='about'>
            <div className="container">
                <div className="content">
                    <h2><span>I'm retired now,</span> but...</h2>
                    <p>I spent 42 years working in software development, data center management, product management and sales. My entire career has been centered on air transport under the employment of SITA and its spin-off company, CHAMP Cargosystems.</p>
                    <p>Air cargo ground handling and airport operations are what I am still very pasionate about.  Please feel free to contact me and let's have a conversation about how I can help!</p>
                    <div>
                        <Link className="contactLink" onClick={scrollToTop} to='/contact'> <button>Contact Me</button></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
