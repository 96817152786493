import React from 'react'
import axios from 'axios';
import './ContactStyles.css'

class Contact extends React.Component {
    state = {
      name: '',
      email: '',
      message: ''
    }
   
    handleFormSubmit( event ) {
        event.preventDefault();
   
        let formData = new FormData();
        formData.append('name', this.state.name)
        formData.append('email', this.state.email)
        formData.append('message', this.state.message)

        axios({
            method: 'post',
            url: 'https://werginz.net/api/api.php/',
            data: formData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(function (response) {
            //handle success
            console.log(response)
            alert('Your request was successfully sent!');  
        })
        .catch(function (response) {
            //handle error
            console.log(response)
        });
    }
   
    render() {    
        return (
            <div className='contact' id="contact_form">
                <div className="container">
                    <div className="form-container">
                        <form>
                            <h2><span>Get In</span> Touch</h2>
                            <div>
                                <label>Name</label>
                                <input 
                                    type="text" 
                                    name="name" 
                                    placeholder='Your name'
                                    onChange={e => this.setState({ name: e.target.value })} 
                                />
                            </div>
                            <div>
                                <label>Email</label>
                                <input 
                                    type="email" 
                                    name="email" 
                                    placeholder='Your email address'
                                    onChange={e => this.setState({ email: e.target.value })} 
                                />
                            </div>
                            <div>
                                <label>Message</label>
                                <textarea 
                                    rows='10' 
                                    name="message" 
                                    placeholder='Your message'
                                    onChange={e => this.setState({ message: e.target.value })} 
                                />
                            </div>
                            <button onClick={e => this.handleFormSubmit(e)} >Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default Contact
