import React from 'react'
import './HomeStyles.css'

const Home = () => {
    return (
        <div className='home'>
            <div className="container">
                <div className="content">
                    <h1>Air Cargo</h1>
                    <h2 className='blue'>Information Technology</h2>
                    <h1>Business Development</h1>
                    <a href="./#contact_form">Contact Me</a>
                </div>
            </div>
        </div>
    )
}

export default Home
